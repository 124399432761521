import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "newPictureTemplate", "newPictureTrigger" ]

  addNewPicture(event) {
    var newInput = this.newPictureTemplateTarget.content.cloneNode(true)
    event.preventDefault();
    this.newPictureTriggerTarget.before(newInput);
  }
}

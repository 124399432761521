import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list", "result" ]

  connect() {
    this.listTarget.addEventListener('change', (event) => {
      this.resultTarget.innerHTML = event.target.labels[0].innerText
    })
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output", "input", "deleteButton" ]
  static classes = [ "imageEmpty", "imagePreview" ]
  static values = { preview: String }

  connect() {
    this.outputTarget.classList.add(this.imageEmptyClass);
  }

  readURL() {
    var input = this.inputTarget
    var output = this.outputTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader()

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0])
      output.classList.remove(this.imageEmptyClass)
      output.classList.add(this.imagePreviewClass)

      this.deleteButtonTarget.classList.remove('hidden')

    }
  }

  deletePicture() {
    this.outputTarget.src = this.previewValue
    this.inputTarget.value = ""
    this.outputTarget.classList.add(this.imageEmptyClass)
    this.outputTarget.classList.remove(this.imagePreviewClass)
    this.deleteButtonTarget.classList.add('hidden')
  }

}

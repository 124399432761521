import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "password", "iconOn", "iconOff" ]

  togglePassword() {
    if (this.passwordTarget.type === "password") {
      this.passwordTarget.type = "text";
      this.iconOffTarget.classList.add('hidden');
      this.iconOnTarget.classList.remove('hidden');
    } else {
      this.passwordTarget.type = "password";
      this.iconOffTarget.classList.remove('hidden');
      this.iconOnTarget.classList.add('hidden');
    }
  }
}

import { Application } from "@hotwired/stimulus"
import Dropdown from 'stimulus-dropdown'
import { Modal } from "tailwindcss-stimulus-components"
import Lightbox from 'stimulus-lightbox'

const application = Application.start()
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('lightbox', Lightbox)

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }

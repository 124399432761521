import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "otherReasonOpen", "otherReasonClose" ]
  static targets = [ "otherReason" ]

  connect() {
    this.otherReasonTarget.classList.add(this.otherReasonCloseClass);
  }

  toggleOtherReason() {
    if (this.otherReasonTarget.classList.contains(this.otherReasonCloseClass)) {
      this.otherReasonTarget.classList.remove(this.otherReasonCloseClass);
      this.otherReasonTarget.classList.add(this.otherReasonOpenClass);
    } else {
      this.otherReasonTarget.classList.remove(this.otherReasonOpenClass);
      this.otherReasonTarget.classList.add(this.otherReasonCloseClass);
    }
  }
}
